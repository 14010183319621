
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/porn/deals",
      function () {
        return require("private-next-pages/porn/deals.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/porn/deals"])
      });
    }
  