export default function DiscountsSchema(siteNumber) {
    return {
        '@context': 'https://schema.org/',
        '@type': 'Webpage',
        name: 'Porn Discounts & Deals',
        url: 'https://www.rabbitsreviews.com/porn/deals',
        hasoffercatalog: {
            '@type': 'offercatalog',
            name: 'Porn discounts and deals',
            description:
                'Access exclusive porn discounts to 1,200+ sites, with honest reviews and ratings. Enter for the hottest deals and shop safely!',
            numberofitems: `${siteNumber}`
        }
    };
}
