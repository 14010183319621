import SortSubTabs from '@components/partials/listPage/sortSubTabs';
import CarouselLeft from '@components/svgs/svgCarouselLeft';
import CarouselRight from '@components/svgs/svgCarouselRight';
import styles from '@styles/common-scss/carousel-cards/_carouselRouter.module.scss';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';

interface CarouselProps {
    items: any;
    type: string;
    categoryTitleSlugData: any;
    style?: any;
}

interface ScrollableState {
    start: boolean;
    end: boolean;
    width: number;
}

const CarouselRouter: FC<CarouselProps> = ({ items, type, categoryTitleSlugData, style = '' }): ReactElement => {
    const containerRef = useRef<HTMLInputElement>(null);
    const [scrollable, setScrollable] = useState<ScrollableState>({
        start: true,
        end: false,
        width: 0
    });

    const scrollDirection = (direction: number, type: string): void => {
        if (containerRef.current) {
            const scrollValueX = containerRef.current.clientWidth >= 720 ? 160 : 130; // log the X value added to scroll
            containerRef.current.scrollBy({
                left: direction * scrollValueX,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container && container.scrollWidth === container.clientWidth) {
            setScrollable((prevState) => ({
                ...prevState,
                start: true,
                end: container.scrollWidth - container.clientWidth === 0 ? true : false
            }));
        }
    }, []);

    const handleScroll = (): void => {
        if (containerRef.current) {
            const container = containerRef.current;
            const scrollableWidth = container.scrollWidth - container.clientWidth;
            const currentScrollLeft = container.scrollLeft;
            const isAtBegin = currentScrollLeft === 0;
            const isAtEnd = scrollableWidth === 0 ? true : currentScrollLeft >= scrollableWidth - 5;

            setScrollable((prevState) => ({
                ...prevState,
                start: isAtBegin,
                end: isAtEnd
            }));
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            container.addEventListener('scroll', handleScroll);
            // Clean up the interval on component unmount
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    function getStyle(value: string) {
        const records: Record<string, string> = {
            SUBTABS: `${styles.list} ${styles.subtabs}`
        };
        return records[value] || styles.list;
    }

    return (
        <div className={`${styles.wrapper} ${style.type ? styles[style.type] : styles['default']}`}>
            <h2>{items.title}</h2>
            <div className={`${styles.container} ${style.container ? styles[style.container] : ''}`}>
                <div className={styles.row}>
                    {/* Video Carousel Del margin */}
                    <section
                        className={`${styles.transform} ${style.container ? styles.left : styles.default}`}
                        ref={containerRef}>
                        {/* Render your cards here */}
                        <ul className={getStyle(type.toUpperCase())}>
                            <SortSubTabs categoryTitleSlugData={categoryTitleSlugData} />
                        </ul>
                    </section>
                </div>
                {/* Additional buttons for scrolling */}
                <div
                    className={`${styles.navigation} ${style.navigation ? styles[style.navigation] : ''} ${
                        scrollable.start === false && scrollable.end === false ? styles.default : ''
                    } ${scrollable.start ? styles.end : ''} ${scrollable.end ? styles.start : ''} `}>
                    <button onClick={() => scrollDirection(-1, type)} disabled={scrollable.start}>
                        <CarouselLeft />
                    </button>

                    <button onClick={() => scrollDirection(1, type)} disabled={scrollable.end}>
                        <CarouselRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CarouselRouter;
