import Layout from '@components/layout';
import BreadcrumbsNav from '@components/partials/common/breadcrumbsNav';
import CarouselRouter from '@components/partials/common/carousel-cards/carouselRouter';
import SplitIntroText from '@components/partials/common/introTextSplit';
import Pagination from '@components/partials/common/navigation/pagination';
import FlexPagination from '@components/partials/listPage/FlexPagination';
import SortTabsV2 from '@components/partials/listPage/sortTabsV2';
import BreadcrumbList from '@components/partials/schemas/breadcrumbList';
import DiscountsSchema from '@components/partials/schemas/discountsSchema';
import { getHolidayInfo } from '@data/holiday';
import styles from '@styles/list-page-scss/_listPage.module.scss';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { findNextPageSlug, findPrevPageSlug } from '../../lib/repository/pageFinder';
import { CardDataArrayType } from '../../lib/types/cardProps';
import { holidayProps } from '../../lib/types/holidayProps';
import { paginationLimit } from '../../lib/web/config';

interface Props {
    holidayMenuContent: holidayProps;
    categoryTitleSlugData: any;
}

export default function PornDiscounts({ holidayMenuContent, categoryTitleSlugData }: Props): JSX.Element {
    const title = 'Porn Discounts & Deals';
    const router = useRouter();

    const [discountData, setDiscountData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [seoTexts, setSeoTexts] = useState(['', '']);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const queryParams = new URLSearchParams();

            if (router.query.page) {
                const offset = (Number(router.query.page) - 1) * 10; // Replace `10` with paginationLimit
                queryParams.append('offset', offset.toString());
            }
            if (router.query.category) {
                const categories = Array.isArray(router.query.category)
                    ? router.query.category
                    : router.query.category.split(',');
                const matchedCategories = categories
                    .map((category) => categoryTitleSlugData.find((cat) => cat.slug === category)?.slug)
                    .filter((matchedCategory) => matchedCategory !== undefined)
                    .join(',');
                if (matchedCategories) {
                    queryParams.append('category', matchedCategories);
                }
            }
            if (router.query.sort) {
                queryParams.append('sort', router.query.sort as string);
            }

            const filteredLinkToFetch = `${process.env.NEXT_PUBLIC_API_DOMAIN}/v2/sites/discounts?limit=${paginationLimit}&${queryParams.toString()}`;
            const discountApiHit = await fetch(filteredLinkToFetch);
            const discountData = await discountApiHit.json();

            if (discountData.discount?.error || discountData.discount?.results?.length === 0) {
                return { notFound: true };
            }
            setDiscountData(discountData);

            //some of the intro text strings had links in them with target="_blank". And lighthouse was complaining about those anchor tags
            // not having a rel="noopener". So I add them manually here if anchor tags exist in the intro text.
            let seoTextA = discountData.discount.discountTitle.seoSpotA;
            let seoTextB = discountData.discount.discountTitle.seoSpotB;

            if (seoTextA.indexOf('<a target="_blank"') > 0) {
                seoTextA = seoTextA.split('<a target="_blank"').join('<a target="_blank" rel="noopener"');
            }
            if (seoTextB.indexOf('<a target="_blank"') > 0) {
                seoTextB = seoTextB.split('<a target="_blank"').join('<a target="_blank" rel="noopener"');
            }
            setSeoTexts([seoTextA, seoTextB]);
            setLoading(false);
        };
        fetchData();
    }, [router.query]); // Refetch when `query` changes

    const tabs = [
        { label: 'RELEVANCE', value: 'relevance' },
        { label: 'SCORE', value: 'score' },
        { label: '% OFF', value: 'percentage' },
        { label: 'LOWEST $', value: 'price' }
    ];

    return (
        <Layout holiday={holidayMenuContent}>
            <Head>
                <title>Porn Discounts & Deals | Get 1,200+ Porn Deals Today</title>
                <meta
                    name="description"
                    content="Access exclusive porn discounts to 1,200+ sites, with honest reviews and ratings. Enter for the hottest deals and shop safely!"
                />
                <meta name="keywords" content="porn discounts,porn deals" />
                <meta property="og:title" content="Porn Discounts & Deals | Get 1,200+ Porn Deals Today" />
                <meta
                    property="og:description"
                    content="Access exclusive porn discounts to 1,200+ sites, with honest reviews and ratings. Enter for the hottest deals and shop safely!"
                />
                <meta property="og:image" content="https://assets.rabbitsreviews.com/images/rr-logo/00-default/OGLogo.png" />
                <meta property="og:url" name="title" content={process.env.NEXT_PUBLIC_APP_DOMAIN + router.pathname} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_DOMAIN + router.pathname} />
                {/* finds the next page to construct '<link rel="next" href="porn/deals?page=${nextPageNumber}"/>' */}
                {Number(router.query.page) !==
                    Math.ceil(Number(discountData?.discount.discountTitle.numberOfSites / paginationLimit)) &&
                Number(discountData?.discount.discountTitle.numberOfSites) > paginationLimit ? (
                    <link rel="next" href={findNextPageSlug(router)} />
                ) : (
                    ''
                )}

                {/* finds the next page to construct '<link rel="prev" href="porn/deals?page=${prevPageNumber}">' */}
                {router.query.page && Number(router.query.page) > 1 ? <link rel="prev" href={findPrevPageSlug(router)} /> : ''}

                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                            DiscountsSchema(
                                discountData?.discount.discountTitle.numberOfSites
                                    ? discountData?.discount.discountTitle.numberOfSites
                                    : 0
                            )
                        )
                    }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(
                            BreadcrumbList({
                                disablePrimary: true,
                                disableSecondary: true
                            })
                        )
                    }}
                />
            </Head>
            <div className={styles.listPage}>
                <BreadcrumbsNav style="xm15" disablePrimary={true} disableSecondary={true} />
                <h1 className={styles.dealsTitle}>{title}</h1>

                <div className={styles.filters}>
                    <SortTabsV2 tabs={tabs} style="deals" />
                    {/* SubFilter Section */}
                    <CarouselRouter
                        categoryTitleSlugData={categoryTitleSlugData}
                        type="subtabs"
                        style={{ type: 'subtabs' }}
                        items={{ links: [], title: 'Filter by category' }}
                    />
                </div>
                {loading ? (
                    <div className={styles.skeletonLoader}>
                        <div className={styles.shimmer}></div>
                    </div>
                ) : (
                    <>
                        <FlexPagination holiday={holidayMenuContent} cards={discountData.discount.results} tag={'discounts'} />
                        <Pagination numberOfSites={discountData.discount.discountTitle.numberOfSites} />
                    </>
                )}
                {/* if youre on a paginated page,say page=2, then dont show the Intro Box */}
                {seoTexts && seoTexts[0] && !router.query.page ? (
                    <div className={styles.listIntro}>
                        <SplitIntroText introContent={seoTexts[0]} />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const holidayMenuContent = await getHolidayInfo();

    const categoriesApi = await fetch(`${process.env.NEXT_PUBLIC_API_DOMAIN}/v1/sites/categories`);
    const data = await categoriesApi.json();
    const sortedCategories = data.allCategories.sort((a, b) => b.numberOfSites - a.numberOfSites);
    const categoryTitleSlugData = sortedCategories.map((category) => ({
        title: category.name.toLocaleLowerCase(),
        slug: category.slug.toLocaleLowerCase()
    }));
    return {
        props: {
            holidayMenuContent,
            categoryTitleSlugData
        }
    };
};
